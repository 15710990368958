import React, { useState } from "react";
import queryString from "query-string";

import {
  Box,
  Typography,
  Button,
  Paper,
  Chip,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Language as WebsiteIcon,
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  Http as HttpIcon,
} from "@material-ui/icons";

import GoodMatchDialog from "./GoodMatchDialog.js";
import MoreInfoDialog, { MoreInfoButton } from "./MoreInfoDialog.js";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "100%",
    maxWidth: "100%",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
}));

export default function SearchResultCard({ result }) {
  const classes = useStyles();

  const [moreInfoOpen, setMoreInfoOpen] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isFeedbackGood, setIsFeedbackGood] = useState(false);

  const handleIsGoodOpen = (status) => {
    setIsFeedbackOpen(true);
    setIsFeedbackGood(status);
  };

  const {
    id,
    title,
    firstname,
    lastname,
    department,
    post,
    academic_score,
    explanations,
    faculty,
    pwp,
  } = result;

  return (
    <Paper className={classes.root} key={id}>
      {/* DIALOG: Good Match */}
      <GoodMatchDialog
        open={isFeedbackOpen}
        value={isFeedbackGood}
        result={result}
        onChange={setIsFeedbackGood}
        onClose={() => {
          setIsFeedbackOpen(false);
        }}
      />
      {/* DIALOG: More Info */}
      <MoreInfoDialog
        title="NLP-based search and ranking algorithm"
        open={moreInfoOpen}
        handleClose={() => setMoreInfoOpen(false)}
      >
        <Typography variant="h6">Matching criteria</Typography>
        <Typography gutterBottom>
          We return an <i>explanation</i> vector of features (unigrams and/or
          bigrams) that describes the match between an academic profile and and
          the query. Then, we use this vector to compute the explanation score
          for the query: a bigram gets a score of 10 and a unigram gets a score
          of 1. The choice of these unit scores is driven by having a difference
          of an order of magnitude between bigrams and the unigrams that compose
          them as some words may appear in many, varied contexts (e.g.
          ‘analysis’ and ‘mining’). Thus, for example, ‘sentiment analysis’ is
          given a higher score than either ‘sentiment’ or ‘analysis’, leading to
          a better selection of relevant authors.
        </Typography>
        <Typography variant="h6">Relevance Scores</Typography>
        <Typography>
          We also compute an <i>academic score</i> for each academic profile,
          based on the year of publications of the papers from which the
          abstracts are drawn. Any publication that contains a term in the query
          and is older than 20 years receives a score of 0.01. Every year in the
          most recent 20 years (the current year included) receives a scorebased
          on its position in a row vector of 20 evenly spaced pointsbetween 0.05
          and 1. For example, a paper from the year {new Date().getFullYear()}{" "}
          receives a score of 1, whereas a paper from{" "}
          {new Date().getFullYear() - 3} receives a score of 0.85. Note that the
          number of years considered (i.e. 20) for being assigned a score from
          evenly spaced points in an interval was set in order to accommodate
          various breakthroughs in the domains analysed but other values could
          be used.
        </Typography>
      </MoreInfoDialog>
      <Box p={1} width="100%">
        <Box display="flex" flexDirection="row">
          <Box flex="1">
            <Typography
              variant="body1"
              component="h4"
              color="textSecondary"
              gutterBottom={false}
              style={{ paddingBottom: 5 }}
            >
              {department}
              {faculty && <span style={{ fontSize: 14 }}> | {faculty}</span>}
            </Typography>
            <Typography variant="h5" component="h2">
              {title} {firstname} {lastname}
            </Typography>
            <Typography
              color="textSecondary"
              variant="h6"
              component="h3"
              style={{ paddingBottom: 12 }}
            >
              {post}
            </Typography>
            <Box display="flex" flexDirection="row" alignItems="flex-start">
              <MoreInfoButton
                size="small"
                onClick={() => setMoreInfoOpen(true)}
              />
              <Box pl={1} display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Typography variant="body2" component="p">
                    Why was this person matched?
                  </Typography>
                  {explanations.map((explanation, idExplanation) => (
                    <Box px={1} py={0.25} key={idExplanation}>
                      <Chip
                        size="small"
                        label={explanation}
                        key={idExplanation}
                      />
                    </Box>
                  ))}
                </Box>
                <Box display="flex" flexDirection="row" alignItems="flex-end">
                  <Box>
                    <Typography variant="body2" component="p">
                      Is this a good match? &nbsp;&nbsp;
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <IconButton
                      color="secondary"
                      size="small"
                      variant="contained"
                      onClick={() => handleIsGoodOpen(true)}
                    >
                      <ThumbUpIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => handleIsGoodOpen(false)}
                    >
                      <ThumbDownIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            {/* Academic Score */}
            <Box
              display="flex"
              flex="1"
              flexDirection="column"
              alignItems="flex-end"
            >
              {academic_score >= 0.1 && (
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Chip
                    label={
                      "Relevance Score " +
                      Math.round(academic_score * 10, 2) / 10
                    }
                  />
                  <MoreInfoButton
                    size="small"
                    onClick={() => setMoreInfoOpen(true)}
                  />
                </Box>
              )}
            </Box>
            {/* Links */}
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {/* Symplectic */}
              <Box mx={1}>
                <Button
                  variant="outlined"
                  href={
                    "https://www.imperial.ac.uk/SymplecticElements/userprofile.html?" +
                    queryString.stringify({
                      uid: id,
                    })
                  }
                >
                  <img
                    src="/imperial.png"
                    alt="Imperial College Icon"
                    width="25"
                  />
                  <Box pl={1}>Symplectic</Box>
                </Button>
              </Box>

              {/* PWP */}
              {pwp && (
                <Box mx={1}>
                  <Button
                    variant="outlined"
                    href={pwp}
                    // startIcon={<WebsiteIcon />}
                  >
                    <img
                      src="/imperial.png"
                      alt="Imperial College Icon"
                      width="25"
                    />
                    <Box pl={1}>PWP</Box>
                  </Button>
                </Box>
              )}

              {/* DBLP */}
              {department.toLowerCase().includes("computing") && (
                <Box mx={1}>
                  <Button
                    variant="outlined"
                    href={
                      "https://dblp.uni-trier.de/search?" +
                      queryString.stringify({
                        q: firstname + " " + lastname,
                      })
                    }
                  >
                    <i className="ai ai-dblp ai-2x" />
                    <Box pl={1}>DBLP</Box>
                  </Button>
                </Box>
              )}
              {/* Google Scholar */}
              <Box mx={1}>
                <Button
                  variant="outlined"
                  href={
                    "https://scholar.google.com/scholar?" +
                    queryString.stringify({
                      q:
                        firstname + " " + lastname + " Imperial College London",
                    })
                  }
                >
                  <i className="ai ai-google-scholar ai-2x" />
                  <Box pl={1}>Scholar</Box>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
