import React from "react";

import { Box, Typography, Button, Collapse } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

import GeneralFeedbackDialog from "./GeneralFeedbackDialog.js";

import {
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

export default function PrototypeBanner() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  return (
    <Box display="flex" flexDirection="column">
      <GeneralFeedbackDialog open={open} onClose={() => setOpen(false)} />
      {/* <Collapse in={false}> */}
      <div className={classes.root}>
        <Alert severity="warning">
          <AlertTitle>This is a prototype!</AlertTitle>
          Please leave a feedback for each of the academics matched by EPIC, for
          each of the academic profile use {/* <ul> */}
          {/* <li> */}
          <ThumbUpIcon fontSize="small" /> to confirm the match, or
          {/* </li> */}
          {/* <li> */} <ThumbDownIcon fontSize="small" /> to report an error.
          {/* </li> */}
          {/* </ul> */}
        </Alert>
        <Alert
          severity="warning"
          action={
            <>
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={() => setOpen(true)}
              >
                Leave Feedback
              </Button>
            </>
          }
        >
          If you instead have any other <i>generic feedback</i>, then click
          here, e.g. misbehaviours in the interface, API error, new features
          proposal.
        </Alert>
        <Box minHeight={10} />
      </div>
      {/* </Collapse> */}
    </Box>
  );
}
