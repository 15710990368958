import { Typography } from "@material-ui/core";

import { LogoText as Logo } from "./Logo.js";

export function GeneralDescriptionParagraph() {
  return (
    <Typography gutterBottom>
      This is (ExPert search at Imperial College London) - a prototype tool for
      matching queries in natural language to academics at Imperial. <Logo /> is
      built from papers in <i>Symplectic</i> using natural language processing
      techniques. <Logo /> is the result of a collaboration between the Faculty
      of Natural Science, the Department of Computing and ICT.
    </Typography>
  );
}

export function CapabilitiesTitle() {
  return (
    <Typography variant="h6">
      What can <Logo /> do?
    </Typography>
  );
}

export function CapabilitiesParagraph() {
  return (
    <Typography gutterBottom>
      <Logo /> allows to search for academic profiles at Imperial College London
      using natural language queries. <Logo /> will return academic profiles
      based on the content of their papers indexed in Sympletic.
    </Typography>
  );
}

export function Capabilities() {
  return (
    <>
      <CapabilitiesTitle />
      <CapabilitiesParagraph />
    </>
  );
}

export function LoginRequestParagraph() {
  return (
    <Typography gutterBottom>
      Please login using your College credentials to access the search engine.
    </Typography>
  );
}
