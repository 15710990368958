import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Typography,
  Box,
  Paper,
  IconButton,
  Divider,
  Grid,
  InputBase,
  Icon,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Search as SearchIcon, Clear as ClearIcon } from "@material-ui/icons";

import { useParams } from "../api.js";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: ({ kind }) =>
      kind === "main" ? "3px 8px 3px 24px" : "0px 8px 0px 16px",
    display: "flex",
    alignItems: "center",
    minWidth: 275,
    flex: "1",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: "1",
  },
  divider: {
    height: 28,
    margin: 4,
  },
  button: {
    padding: ({ kind }) => (kind === "main" ? 12 : 5),
  },
}));

/**
 * SearchBox
 *
 * kind
 *  - "main": for the main page (fixed width)
 *  - "header": infinite width search box for the header
 */
export default function SearchBox({ kind = "main" }) {
  const classes = useStyles({ kind });

  const [search, params, setParams, setParam] = useParams();

  const [query, setQuery] = useState(
    params.query === undefined ? "" : params.query
  );

  // When searching we call the `searchHandler` passed as property
  const handleSubmit = (event) => {
    event.preventDefault();
    if (query != "") {
      setParams({ query, page: 0 });
    }
  };

  return (
    <Paper
      component="form"
      className={classes.root}
      onSubmit={handleSubmit}
      variant="outlined"
    >
      <InputBase
        value={query}
        className={classes.input}
        placeholder="Search with EPIC"
        inputProps={{ "aria-label": "search epic" }}
        onChange={(e) => setQuery(e.target.value)}
        autoFocus={true}
      />
      {query.length > 0 && (
        <IconButton
          type="reset"
          variant="contained"
          aria-label="clear search query"
          value="Reset"
          className={classes.button}
          onClick={() => setQuery("")}
        >
          <ClearIcon />
        </IconButton>
      )}
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        type="submit"
        variant="contained"
        aria-label="search on epic"
        color="secondary"
        value="Submit"
        className={classes.button}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
