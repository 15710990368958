import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import queryString, { exclude } from "query-string";

export function get(request) {
  return axios.get(process.env.REACT_APP_API + request);
}

export function post(request, data) {
  return axios.post(process.env.REACT_APP_API + request, data);
}

const queryStringOptions = {
  arrayFormat: "comma",
  skipEmptyString: false,
  skipNull: false,
};

export function useGET({ url, triggers = [] }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [elapsed, setElapsed] = useState(0);
  const [error, setError] = useState();
  useEffect(() => {
    setLoading(true);
    var start = Date.now();
    get(url, { timeout: 3600 * 1000 })
      .then(({ data }) => {
        setData(data);
        setError();
        setElapsed(() => Date.now() - start);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
        setElapsed(() => Date.now() - start);
        setLoading(false);
      });
  }, triggers);
  return [data, error, elapsed, loading];
}

export function useParams() {
  //Options

  // Get history and location
  let history = useHistory();
  let { search } = useLocation();

  // Params
  const params = queryString.parse(search, queryStringOptions);

  // Params Setter
  const setParams = (updatedParams) => {
    console.log("Setting", updatedParams);
    history.push(
      "/search?" +
        queryString.stringify(
          { ...params, ...updatedParams },
          queryStringOptions
        )
    );
  };

  // Single param setter
  const setParam = (param, value) => {
    console.log("Setting", param, "to", value);
    history.push(
      "/search?" +
        queryString.stringify({ ...params, [param]: value }, queryStringOptions)
    );
  };

  return [search, params, setParams, setParam];
}

// Hook
export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}
