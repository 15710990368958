import React from "react";
import APITable from "../components/APITable.js";

export default function FeedbacksPage() {
  return (
    <>
      <APITable url="general_feedback" />
      <APITable url="match_feedback" />
    </>
  );
}
