import React, { useState, useEffect } from "react";
import { useTheme, withStyles } from "@material-ui/core/styles";

import {
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 * data - contains the checkboxes data
 * {
 *  key : { label : String , value : Boolean}
 *  ...
 * }
 *
 * onCheckboxChangeHandler(key, value)
 */
export default function CheckBoxes({
  options,
  enabled,
  onChange,
  title,
  description,
  disabledValue = true,
  colorNegative,
}) {
  const theme = useTheme();

  // const [values, setValues] = useState(
  //   options.filter((option) => option.value).map((option) => option.key)
  // );

  const onChangeHandler = (event) => {
    const key = event.target.name;
    const checked = enabled ? event.target.checked : true;
    var values = options
      .filter((option) => option.value)
      .map((option) => option.key);
    values = checked
      ? values.concat([key])
      : values.filter((val) => val != key);
    // if (values.length == 0) {
    //   values = null;
    // }
    onChange(values);
  };

  const colorPalette =
    colorNegative === undefined
      ? theme.palette.secondary
      : colorNegative
      ? theme.palette.error
      : theme.palette.success;

  const color = enabled
    ? colorPalette.main
    : colorPalette.fadedB !== undefined
    ? colorPalette.fadedB
    : colorPalette.faded;

  return (
    <>
      {options !== undefined ? (
        <Box display="flex">
          {options === undefined ? (
            "Loading..."
          ) : (
            <FormControl component="fieldset" key="control">
              {title && (
                <FormLabel component="legend" key="legend">
                  {title}
                </FormLabel>
              )}
              {description && (
                <FormHelperText key="helper">{description}</FormHelperText>
              )}
              <FormGroup key="group">
                {options.map((option) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={enabled ? option.value : disabledValue}
                        style={{
                          color: color,
                        }}
                        onChange={onChangeHandler}
                        name={option.key}
                        key={"checkbox_" + option.id}
                      />
                    }
                    label={option.label}
                    key={"control_" + option.id}
                  />
                ))}
              </FormGroup>
            </FormControl>
          )}
        </Box>
      ) : (
        <>Hi</>
      )}
    </>
  );
}
