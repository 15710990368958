import React from "react";

export default function ImperialAppBar({ maxWidth = "1000px", children }) {
  return (
    <header id="header" className="navigation" role="banner">
      <div
        className="container cf"
        // style={{ maxWidth: maxWidth, width: "100%" }}
      >
        <div className="navigation-header">
          <h1 className="logo">
            <a
              href="/"
              className="brand"
              title="Imperial College London Home"
            />
          </h1>
          <section id="news-header"></section>
        </div>
        <nav role="navigation" id="primary-nav" className="wide">
          <ul className="nav nav-primary"></ul>
          <ul className="nav nav-secondary"></ul>
        </nav>
        <section className="section-nav">
          <h2 id="section-title">
            <a href="#menu-8819" data-toggle="section">
              ExPert Search
            </a>
          </h2>
          <nav className="menus">
            {/* This ul -> li is necessary to trigger to keep the appbar visible when scrolling */}
            <ul>
              <li></li>
            </ul>
            {children}
          </nav>
        </section>
      </div>
    </header>
  );
}
