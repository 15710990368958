import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";

export default function ErrorPage({ error }) {
  const theme = useTheme();

  useEffect(() => {
    document.title = "Error " + error;
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      minHeight="100vh"
      width="100%"
      minWidth="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        maxHeight="30%"
        minHeight="30%"
        maxWidth="60%"
        minWidth="60%"
      >
        <Paper>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            m={3}
            height="30vh"
            width="50vh"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h1">{error}</Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
