import React from "react";

import ImperialFooter from "../components/ImperialFooter.js";

export default function ImperialContentContainer({ title, children }) {
  return (
    <div id="content-box">
      <div id="page">
        <div className="container content-template" id="content">
          <div className="row top flow-opposite" id="main">
            <div className="col lg-12" role="main" id="primary-content">
              {title !== undefined && (
                <div className="col lg-12 page-heading">
                  <h1>{title}</h1>
                </div>
              )}
              <div className="module">{children}</div>
            </div>
          </div>
        </div>
        <div className="social cf container">
          <a
            href="javascript:window.print();"
            className="share-link share-print"
          >
            Print
          </a>
          <a
            target="_blank"
            className="share-link share-mail"
            href="https://.addtoany.com/email"
          >
            Email
          </a>
          <a
            target="_blank"
            className="share-link share-all"
            href="https://.addtoany.com/share_save"
          >
            Share
          </a>
        </div>
      </div>
      <ImperialFooter />
    </div>
  );
}
