// import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

import { fade } from "@material-ui/core/styles/colorManipulator";

const imperialDark = "#002147";
const imperialNormal = "#003E74";
const imperialLight = "#006EAF";
const imperialAccent = "#379F9F";
const imperialBackground = "#FFFFFF";
const imperialGreyLight = "#EBEEEE";
const imperialGreyDark = "#9D9D9D";

const imperialRed = "#A51900";
const imperialOrange = "#EC7300";
const imperialGreen = "#02893B";

// Imperial Color theme
const theme = createMuiTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: imperialNormal,
      faded: fade(imperialNormal, 0.3),
    },
    secondary: {
      main: imperialLight,
      faded: fade(imperialLight, 0.3),
    },
    accent: {
      main: imperialAccent,
      faded: fade(imperialAccent, 0.8),
    },
    grey: {
      main: imperialGreyLight,
      faded: fade(imperialGreyLight, 0.8),
    },
    error: {
      main: imperialRed,
      faded: fade(imperialRed, 0.5),
      fadedB: fade(imperialRed, 0.1),
    },
    warning: {
      main: imperialOrange,
    },
    success: {
      main: imperialGreen,
      faded: fade(imperialGreen, 0.5),
      fadedB: fade(imperialGreen, 0.1),
    },
    info: {
      main: imperialLight,
    },
    background: {
      default: "#FEFEFE",
      main: "#FEFEFE",
      //   shade: imperialGreyLight,
    },
    white: {
      main: "#FFFFFF",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "0.7em",
        },
        "*::-webkit-scrollbar-track": {
          "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.15)",
          // outline: "1px solid slategrey",
        },
      },
    },
  },
});

export default theme;
