import React from "react";

import { Box } from "@material-ui/core";

import SearchResults from "../components/SearchResults.js";
import PrototypeBanner from "../components/PrototypeBanner.js";

export default function ResultsPage() {
  return (
    <>
      {/* <Box
        position="absolute"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100%"
        width="100%"
      > */}
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        alignItems="center"
        width="100%"
        maxWidth="1300px"
        py={3}
        px={5}
      >
        <PrototypeBanner />
        <SearchResults />
      </Box>
    </>
  );
}
