import React, { useState, useEffect } from "react";

import {
  Box,
  AppBar,
  Toolbar,
  Container,
  useScrollTrigger,
  Link,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { useGET, useParams } from "../api.js";
import Logo from "../contents/Logo.js";
import SearchBox from "./SearchBox.js";
import DiscoveryBox from "./DiscoveryBox.js";
import Filter from "./Filter.js";
import CheckboxesWithReset from "./CheckboxesWithReset.js";
import CheckboxSelect from "./CheckboxSelect.js";
import BipolarSwitch from "./BipolarSwitch.js";
import ImperialAppBar from "../layout/ImperialAppBar.js";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "relative",
    },
    bluetoolbar: {
      paddingLeft: 0,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.main,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      width: "50px",
      height: "50px",
    },
    scrollable: {
      overflow: "auto",
      maxHeight: 300,
    },
  };
});

export default function SearchAppBar({ maxWidth = "1800px" }) {
  const [search, params, setParams, setParam] = useParams();

  const classes = useStyles();
  const theme = useTheme();

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const [faculties] = useGET({ url: "faculties" });
  const [positions] = useGET({ url: "positions" });
  const [departments] = useGET({ url: "departments" });
  const [excludeDept, setExcludeDept] = useState(
    params["department_out"] != undefined
  );

  const setExcludeDeptHandler = (
    exclude,
    inName = "department_in",
    outName = "department_out"
  ) => {
    setExcludeDept(exclude);
    if (exclude && params[inName] != undefined) {
      setParams({
        ...params,
        [inName]: undefined,
        [outName]: params[inName],
      });
    }
    if (!exclude && params[outName] != undefined) {
      setParams({
        ...params,
        [inName]: params[outName],
        [outName]: undefined,
      });
    }
  };

  const filterArgumentsGenerator = (param_names) => ({
    active: param_names
      .map((param) => params[param] != undefined)
      .reduce((total, num) => total || num),
  });

  const argumentsGenerator = (data, param) => {
    const paramValue =
      params[param] != undefined
        ? params[param] instanceof Array
          ? params[param]
          : params[param] === ""
          ? []
          : [params[param]]
        : [];
    return {
      options:
        data != undefined
          ? Object.entries({
              ...data,
              ...Object.fromEntries(
                paramValue.map((key) => [key, { ...data[key], value: true }])
              ),
            }).map(([key, option], id) => ({
              id: id,
              key: key,
              ...option,
            }))
          : // .sort((a, b) => -1 * (a.value > b.value))
            undefined,
      enabled: params[param] != undefined,
    };
  };

  return (
    <>
      <Toolbar className={classes.bluetoolbar}>
        <Container>
          <Box
            maxWidth={maxWidth}
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Link href="/" color="inherit" underline="none">
              <Box minWidth="150px" maxWidth="150px">
                <Logo kind="header" />
              </Box>
            </Link>
            <Box flex="1" pl={2}>
              <SearchBox kind="header" />
            </Box>
            <Box display="flex" pl={2}>
              <DiscoveryBox />
            </Box>
            <Box minWidth="30px" maxWidth="30px" />
          </Box>
        </Container>
      </Toolbar>
      <Toolbar className={classes.bluetoolbar}>
        <Container>
          <Box
            maxWidth={maxWidth}
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Box width="70px" />
            <Box>
              <Button color="inherit" size="large" disableRipple>
                Filters:
              </Button>
            </Box>
            <Filter
              label="Faculties"
              {...filterArgumentsGenerator(["faculty_in"])}
            >
              {faculties === undefined ? (
                <CircularProgress color="secondary" />
              ) : (
                <CheckboxesWithReset
                  description="Included faculties"
                  {...argumentsGenerator(faculties, "faculty_in")}
                  onChange={(keys) => setParam("faculty_in", keys)}
                  onReset={() => setParam("faculty_in", undefined)}
                />
              )}
            </Filter>
            <Filter
              label="Roles"
              {...filterArgumentsGenerator(["position_in", "position_out"])}
            >
              {positions === undefined ? (
                <CircularProgress color="secondary" />
              ) : (
                // <CheckboxesWithReset
                //   description="Included positions"
                //   {...argumentsGenerator(positions, "position_in")}
                //   onChange={(keys) => setParam("position_in", keys)}
                //   onReset={() => setParam("position_in", undefined)}
                // />
                <Box display="flex" flexDirection="row">
                  <CheckboxesWithReset
                    description="Include positions"
                    {...argumentsGenerator(positions, "position_in")}
                    onChange={(keys) => {
                      setParams({
                        position_in: keys,
                        position_out:
                          params["position_out"] instanceof Array
                            ? params["position_out"].filter(
                                (v) => !keys.includes(v)
                              )
                            : keys.includes(params["position_out"])
                            ? undefined
                            : params["position_out"],
                      });
                    }}
                    onReset={() => setParam("position_in", undefined)}
                    colorNegative={false}
                  />
                  <CheckboxesWithReset
                    description="Exclude positions"
                    {...argumentsGenerator(positions, "position_out")}
                    onChange={(keys) => setParam("position_out", keys)}
                    onReset={() => setParam("position_out", undefined)}
                    disabledValue={false}
                    colorNegative={true}
                  />
                </Box>
              )}
            </Filter>
            <Filter
              label="Departments"
              {...filterArgumentsGenerator(["department_in", "department_out"])}
            >
              {departments === undefined ? (
                <CircularProgress color="secondary" />
              ) : (
                <Box display="flex" flexDirection="column">
                  <BipolarSwitch
                    disabled={
                      params["department_in"] === undefined &&
                      params["department_out"] === undefined
                    }
                    value={excludeDept}
                    onChange={setExcludeDeptHandler}
                    label_yes="Include"
                    label_no="Exclude"
                  />
                  <Box>
                    <CheckboxSelect
                      title="Search in these departments only"
                      description="Select the departments..."
                      {...argumentsGenerator(
                        departments,
                        excludeDept ? "department_out" : "department_in"
                      )}
                      onChange={(keys) =>
                        setParam(
                          excludeDept ? "department_out" : "department_in",
                          keys
                        )
                      }
                      negative={excludeDept}
                    />
                  </Box>
                </Box>
              )}
            </Filter>
          </Box>
        </Container>
      </Toolbar>
    </>
  );
}
