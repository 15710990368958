import React, { useState } from "react";
import {
  Typography,
  Box,
  Paper,
  IconButton,
  Divider,
  Grid,
  InputBase,
  Icon,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Search as SearchIcon,
  Pageview as PageviewIcon,
  Clear as ClearIcon,
} from "@material-ui/icons";
import { withRouter } from "react-router-dom";

import { ReactComponent as LogoIcon } from "../logo.svg";
import Logo from "../contents/Logo.js";
import SearchBox from "../components/SearchBox.js";
import WelcomeDialog from "../components/WelcomeDialog.js";
import { useLocalStorage } from "../api.js";
import { Capabilities, GeneralDescriptionParagraph } from "../contents/Text";

export default function HomePage({ history }) {
  // const [showWelcome, setShowWelcome] = useLocalStorage("showWelcome", true);
  // const [welcomeOpen, setWelcomeOpen] = useState(showWelcome);
  return (
    <>
      {/* {showWelcome && (
        <WelcomeDialog
          login={false}
          open={welcomeOpen}
          handleClose={() => setWelcomeOpen(false)}
        />
      )} */}
      {/* <Box
        position="absolute"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
      > */}
      <Box
        display="flex"
        flex="3"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="400"
        maxHeight="100%"
        width="100%"
        py={5}
      >
        <Logo kind="main" />
        <Box maxWidth="600px" width="80%">
          <SearchBox />
        </Box>
        <Box maxWidth="1000px" width="80%" pt={7}>
          <GeneralDescriptionParagraph />
          <Capabilities />
        </Box>
      </Box>
      {/* <Box
        display="flex"
        flex="2"
        maxWidth="100%"
        minWidth="100%"
        maxHeight="40vh"
      /> */}
      {/* </Box> */}
    </>
  );
}
