import React from "react";

import { Typography, Grid, Icon, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ReactComponent as LogoIcon } from "../logo.svg";

const useStyles = makeStyles((theme) => ({
  // style rule
  root: {
    color: theme.palette.primary,
  },
  icon: {
    fontSize: ({ kind }) => (kind === "main" ? 100 : 40),
    fill: ({ kind }) =>
      kind === "main" ? theme.palette.primary.main : "white",
  },
  iconImage: {
    display: "flex",
    height: "inherit",
    width: "inherit",
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

/**
 * Logo
 *
 * kind
 *  - "main" => Big Logo for main page
 *  - "header" => Small (and horizontal) logo for the header
 *
 */
export default function Logo({ kind = "main" }) {
  const classes = useStyles({ kind });
  return (
    <Grid
      container
      direction={kind === "main" ? "column" : "row"}
      alignItems="center"
      justify="center"
      align="center"
      className={classes.root}
    >
      <Grid item>
        <Icon className={classes.icon}>
          <LogoIcon className={classes.iconImage} />
        </Icon>
      </Grid>
      <Grid item>
        <Typography
          variant={kind === "main" ? "h3" : "h4"}
          component="h1"
          align="center"
          color={kind === "main" ? "primary" : "inherit"}
          style={kind !== "main" ? { color: "white" } : {}}
          gutterBottom
        >
          EPIC
        </Typography>
      </Grid>
    </Grid>
  );
}

export function LogoText() {
  const classes = useStyles();
  return <span className={classes.text}>EPIC</span>;
}
