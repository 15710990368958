import React, { useState } from "react";
import { useSnackbar } from "notistack";

import {
  Box,
  Dialog,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";

import { post, useParams } from "../api.js";
import { DialogTitle, DialogContent, DialogActions } from "./CustomDialog.js";
import BipolarSwitch from "./BipolarSwitch.js";

export default function GoodMatchDialog({
  open,
  result,
  value,
  onClose = () => undefined,
  onChange = (value) => undefined,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [search, params] = useParams();

  const [isGoodComment, setIsGoodComment] = useState("");
  const [posting, setPosting] = useState(false);

  const handleClose = () => {
    setPosting(false);
    onClose();
  };

  const handleIsGoodSubmit = (event) => {
    event.preventDefault();
    setPosting(true);
    post("match_feedback/", {
      ismatch: value,
      feedback: isGoodComment,
      query: params,
      result,
    })
      .then(() => {
        enqueueSnackbar("Feedback successfully submitted!", {
          variant: "success",
          autoHideDuration: 5000,
        });
        handleClose();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(
          "Feedback submission failed. Please try again or contact the system administrator.",
          {
            variant: "error",
            persist: true,
          }
        );
        handleClose();
      });
  };

  return (
    <Dialog
      onClose={onClose}
      open={open === true && result !== undefined}
      scroll="paper"
      fullWidth={true}
    >
      <form onSubmit={handleIsGoodSubmit}>
        <DialogTitle onClose={onClose}>Is this a good match?</DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            {/* <Typography gutterBottom>Is this a good match?</Typography> */}
            <BipolarSwitch
              value={!value}
              onChange={(value) => onChange(!value)}
              disabled={posting}
            />
            <TextField
              id="isgood-comment"
              label="Feedback"
              multiline
              fullWidth={true}
              rows={6}
              placeholder={
                value
                  ? "Why is this a good match?"
                  : "Why is this not a good match?"
              }
              value={isGoodComment}
              onChange={(event) => setIsGoodComment(event.target.value)}
              variant="outlined"
              disabled={posting}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {posting && <CircularProgress />}
          <Button
            autoFocus
            color="primary"
            value="Submit"
            type="submit"
            aria-label="submit feedback"
            disabled={posting}
          >
            Submit Feedback
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
