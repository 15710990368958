import React, { useState, useEffect } from "react";

import { Box, Typography } from "@material-ui/core";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import { Pagination as MuiPagination } from "@material-ui/lab";

import { useGET, useParams } from "../api.js";
import LoadingOrError from "./LoadingOrError.js";
import SearchResultCard from "./SearchResultCard.js";
import { scrollTop } from "./TopScroll.js";

const RESULTS_PER_PAGE = 10;

function Pagination({ results, page, onChange, ...props }) {
  return (
    <MuiPagination
      count={Math.ceil(results.length / RESULTS_PER_PAGE)}
      page={page + 1}
      onChange={(event, value) => {
        onChange(value - 1);
        scrollTop();
      }}
      disabled={results.length <= 10}
      {...props}
    />
  );
}

export default function SearchResults() {
  const [search, params, setParams, setParam] = useParams();
  const [results, error, elapsedTime, loading] = useGET({
    url: "search/" + search,
    triggers: [
      params.query,
      params.department_in,
      params.department_out,
      params.position_in,
      params.position_out,
      params.faculty_in,
      params.discovery,
    ],
  });
  console.log(loading);
  console.log(error);

  const setPage = (p) => setParam("page", p);
  if (params.page === undefined) {
    setPage(0);
  }
  const page = parseInt(params.page);

  return (
    <>
      <LoadingOrError
        color="secondary"
        size="large"
        loading={loading}
        error={error}
      />
      {results !== undefined && !loading && (
        <>
          <Box display="flex" pb={2} pl={1} width="100%">
            <Typography variant="body1">
              Found {results.length} results for '{params.query}'
              {elapsedTime < 1000 * 3600 && (
                <span> in {Math.round(elapsedTime / 10) / 100} seconds</span>
              )}
              .
            </Typography>
          </Box>
          {results.length == 0 ? (
            <Typography variant="h4">
              No results{" "}
              <SentimentDissatisfiedIcon
                padding={1}
                style={{ verticalAlign: "middle", marginBottom: 4 }}
                fontSize="inherit"
              />
            </Typography>
          ) : (
            <>
              <Pagination
                results={results}
                page={page}
                onChange={setPage}
                style={{ paddingBottom: 15 }}
              />
              {results
                .slice(page * RESULTS_PER_PAGE, (page + 1) * RESULTS_PER_PAGE)
                .map((result) => (
                  <SearchResultCard result={result} key={result.id} />
                ))}
              <Pagination results={results} page={page} onChange={setPage} />
            </>
          )}
        </>
      )}
    </>
  );
}
