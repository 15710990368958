import React from "react";
import { useTheme } from "@material-ui/core/styles";

import { Box, Button } from "@material-ui/core";
import Checkboxes from "./Checkboxes.js";

export default function CheckboxesWithReset({ enabled, onReset, ...props }) {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" pb={2}>
        <Box display="flex" flex="1" />
        <Button
          style={{
            color: enabled
              ? theme.palette.secondary.main
              : theme.palette.text.disabled,
          }}
          onClick={onReset}
        >
          Clear Filter
        </Button>
      </Box>
      <Box>
        <Checkboxes {...{ enabled, ...props }} />
      </Box>
    </Box>
  );
}
