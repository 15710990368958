import React, { useState } from "react";
import {
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import { LockOutlined as LockOutlinedIcon } from "@material-ui/icons";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";

import Footer from "../components/Footer.js";
import WelcomeDialog from "../components/WelcomeDialog.js";
// import { useLocalStorage } from "../api.js";

const useStyles = makeStyles((theme) => ({
  // paper: {
  //   marginTop: theme.spacing(8),
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  // },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textfield: {
    backgroundColor: "white",
  },
  checkbox: {
    color: "white",
  },
}));

const globalStyles = (theme) => ({
  // "@global": {
  //   body: {
  //     backgroundColor: theme.palette.primary.main,
  //     color: "#FFFFFF",
  //   },
  // },
});

function LoginPage() {
  const classes = useStyles();
  const theme = useTheme();
  // const [welcomeDialogOpen, setWelcomeDialogOpen] = useState(true);
  return (
    <>
      <Box
        bgcolor={theme.palette.primary.main}
        minWidth="100%"
        minHeight="100%"
      >
        <Container component="main" maxWidth="xs">
          {/* <WelcomeDialog
        open={welcomeDialogOpen}
        handleClose={() => setWelcomeDialogOpen(false)}
        login={true}
      /> */}

          <Box display="flex" flexDirection="row" width="100%">
            <img
              src="/imperialTextWhite.png"
              alt="Imperial College London Logo"
              width="70%"
            />
          </Box>
          <form className={classes.form} noValidate>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              className={classes.textfield}
            />
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              className={classes.textfield}
            />
            <FormControlLabel
              control={
                <Checkbox value="remember" className={classes.checkbox} />
              }
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
        </Container>
      </Box>
    </>
  );
}

export default withStyles(globalStyles)(LoginPage);
