import React, { useState } from "react";
import { useSnackbar } from "notistack";

import {
  Box,
  Dialog,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";

import { post, useParams } from "../api.js";
import { DialogTitle, DialogContent, DialogActions } from "./CustomDialog.js";

export default function GeneralFeedbackDialog({
  open,
  onClose = () => undefined,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [search, params] = useParams();

  const [comment, setComment] = useState("");
  const [posting, setPosting] = useState(false);

  const handleClose = () => {
    setPosting(false);
    onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setPosting(true);
    post("general_feedback/", {
      feedback: comment,
      query: params,
    })
      .then(() => {
        enqueueSnackbar("General feedback successfully submitted!", {
          variant: "success",
          autoHideDuration: 5000,
        });
        handleClose();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(
          "General feedback submission failed. Please try again or contact the system administrator.",
          {
            variant: "error",
            persist: true,
          }
        );
        handleClose();
      });
  };

  return (
    <Dialog
      onClose={onClose}
      open={open === true}
      scroll="paper"
      fullWidth={true}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle onClose={onClose}>General Feedback</DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <TextField
              id="general-comment"
              label="Feedback"
              multiline
              fullWidth={true}
              rows={6}
              placeholder="Write your feedback here..."
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              variant="outlined"
              disabled={posting}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {posting && <CircularProgress />}
          <Button
            autoFocus
            color="primary"
            value="Submit"
            type="submit"
            aria-label="submit feedback"
            disabled={posting}
          >
            Submit Feedback
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
