import React from "react";

import { Container, Box } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { useGET } from "../api.js";
import LoadingOrError from "./LoadingOrError.js";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function APITable({ url }) {
  const classes = useStyles();

  const [data, error, elapsed, loading] = useGET({ url: url });

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        my={2}
        alignContent="center"
        alignItems="center"
        width="100%"
      >
        <LoadingOrError
          size="large"
          data={data}
          loading={loading}
          error={error}
        />
        {data !== undefined && (
          <TableContainer component={Paper} width="100%">
            <Table
              className={classes.table}
              size="small"
              aria-label="simple table"
              padding="none"
            >
              <TableHead>
                <TableRow>
                  <TableCell key="id">ID</TableCell>
                  {Object.keys(data[0]).map((key, index) => (
                    <TableCell key={index}>{key}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" key="id">
                      {index}
                    </TableCell>
                    {Object.values(row).map((value, index) => (
                      <TableCell key={index}>
                        {value !== undefined &&
                        value !== null &&
                        typeof value === "object"
                          ? value.$date !== undefined
                            ? new Date(value.$date)
                                .toISOString()
                                .replace("T", " ")
                                .replace(".000Z", " ")
                            : JSON.stringify(value)
                          : value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Container>
  );
}

// export default function FeedbacksPage() {
//   return (
//     <>
//       <Box
//         position="absolute"
//         display="flex"
//         flexDirection="column"
//         justifyContent="center"
//         alignItems="center"
//         minHeight="100%"
//         width="100%"
//       >
//         <SearchAppBar maxWidth="1200px" />
//         <Box
//           display="flex"
//           flex="1"
//           flexDirection="column"
//           alignItems="center"
//           width="100%"
//           maxWidth="1200px"
//           py={3}
//           px={5}
//         >
//           <SearchResults />
//         </Box>
//         <Footer />
//       </Box>
//       <TopScroll />
//     </>
//   );
// }
