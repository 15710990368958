import React, { useState } from "react";

import {
  Box,
  IconButton,
  Button,
  Popper,
  ClickAwayListener,
  Fade,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import PopupState, {
  bindHover,
  bindPopper,
  bindToggle,
} from "material-ui-popup-state";

export default function Filter({ label, children, active }) {
  return (
    <Box px={1}>
      <PopupState variant="popper" popupId={"popper-id-" + label}>
        {(popupState) => (
          <ClickAwayListener onClickAway={popupState.close}>
            <div>
              <Button
                variant="contained"
                color={active ? "secondary" : "default"}
                endIcon={<ExpandMoreIcon />}
                {...bindToggle(popupState)}
              >
                {label}
              </Button>
              <Popper
                {...bindPopper(popupState)}
                disablePortal={true}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <Box p={1.5}>
                        {/* {React.Children.map(children, (child) =>
                          React.isValidElement(child)
                            ? React.cloneElement(child, {
                                activeFilter,
                                setActiveFilter,
                              })
                            : child
                        )} */}
                        {children}
                      </Box>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </div>
          </ClickAwayListener>
        )}
      </PopupState>
    </Box>
  );
}
