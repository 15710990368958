import React, { useState, useEffect } from "react";

import { useScrollTrigger, Fab, Zoom } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { KeyboardArrowUp as KeyboardArrowUpIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => {
  return {
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      width: "50px",
      height: "50px",
    },
  };
});

export function scrollTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

export default function TopScroll() {
  const classes = useStyles();

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
  });

  return (
    <Zoom in={scrollTrigger}>
      <div onClick={scrollTop} role="presentation" className={classes.fab}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </div>
    </Zoom>
  );
}
