import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Layout
import ImperialContentContainer from "./layout/ImperialContentContainer";
import ImperialAppBar from "./layout/ImperialAppBar";

// Appbars
import SearchAppBar from "./components/SearchAppBar";
import EmptyAppBar from "./components/EmptyAppBar";

// Pages
import HomePage from "./pages/HomePage";
import ResultsPage from "./pages/ResultsPage";
import FeedbacksPage from "./pages/Feedbacks";
import SearchHistoryPage from "./pages/SearchHistory";
import ErrorPage from "./pages/ErrorPage.js";
import LoginPage from "./pages/LoginPage.js";

// Utility
import TopScroll from "./components/TopScroll";

function ErrorRoute({ error, ...props }) {
  return (
    <Route
      {...props}
      status={error}
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.statusCode = error;
        }
        return <ErrorPage error={error} />;
      }}
    />
  );
}

function AppBar() {
  return (
    <Switch>
      <Route exact path="/" component={EmptyAppBar} />
      <Route exact path="/search" component={SearchAppBar} />
    </Switch>
  );
}

function ContentTitle() {
  return (
    <Switch>
      <Route exact path="/"></Route>
      <Route exact path="/search">
        Search Results
      </Route>
      <Route exact path="/feedbacks">
        Feedbacks
      </Route>
      <Route exact path="/history">
        Search History
      </Route>
      <Route exact path="/login">
        Login
      </Route>
    </Switch>
  );
}

function Content() {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/search" component={ResultsPage} />
      <Route exact path="/feedbacks" component={FeedbacksPage} />
      <Route exact path="/history" component={SearchHistoryPage} />
      <Route exact path="/login" component={LoginPage} />
      <ErrorRoute error={404} />
    </Switch>
  );
}

export default function App() {
  return (
    <Router>
      <ImperialAppBar>
        <AppBar />
      </ImperialAppBar>
      <ImperialContentContainer title={<ContentTitle />}>
        <Content />
      </ImperialContentContainer>
      <TopScroll />
    </Router>
  );
}
