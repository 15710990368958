import React, { useState, useEffect } from "react";

import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import {
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
} from "@material-ui/icons";
import { Box, Switch, Button } from "@material-ui/core";

const GreenRedSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.success.main,
    "&$checked": {
      color: theme.palette.error.main,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.error.main,
    },
  },
  checked: {},
  track: {
    backgroundColor: theme.palette.success.main,
    opacity: 0.38,
  },
}))(Switch);

export default function BipolarSwitch({
  disabled = false,
  onChange = (value) => undefined,
  value = false,
  label_yes = (
    <>
      <ThumbUpIcon /> &nbsp; Yes
    </>
  ),
  label_no = (
    <>
      <ThumbDownIcon /> &nbsp; No
    </>
  ),
}) {
  const theme = useTheme();
  //   const [value, setValue] = useState(defaultValue);

  const handleChange = (newValue) => {
    // setValue(newValue);
    onChange(newValue);
  };

  return (
    <Box display="flex" flexDirection="row" pb={2}>
      <Button
        style={{
          color: value
            ? theme.palette.text.disabled
            : disabled
            ? theme.palette.success.faded
            : theme.palette.success.main,
        }}
        onClick={() => handleChange(false)}
      >
        {label_yes}
      </Button>
      <Box
        style={{
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <GreenRedSwitch
          checked={value}
          onChange={(event) => handleChange(event.target.checked)}
        />
      </Box>
      <Button
        style={{
          color: !value
            ? theme.palette.text.disabled
            : disabled
            ? theme.palette.error.faded
            : theme.palette.error.main,
        }}
        onClick={() => handleChange(true)}
      >
        {label_no}
      </Button>
    </Box>
  );
}
