import React, { useState, useEffect } from "react";

import { Typography, CircularProgress, Box } from "@material-ui/core";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";

const progressSize = {
  small: "1rem",
  medium: "4rem",
  large: "7rem",
};

const textSize = {
  small: "body1",
  medium: "h5",
  large: "h4",
};

export default function LoadingOrError({
  loading,
  error,
  data = null,
  size = "medium",
  ...props
}) {
  return (
    <>
      {loading && <CircularProgress size={progressSize[size]} {...props} />}
      {error && (
        <Box display="flex" flexDirection="column">
          <Typography variant={textSize[size]}>
            <SentimentDissatisfiedIcon fontSize="inherit" />
            API Error: {error}
          </Typography>
          <Typography variant="body1">
            Refresh the page or if the issue persists contact the administrator.
          </Typography>
        </Box>
      )}
    </>
  );
}
