import React from "react";

import { Typography, Link } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

function Copyright({ color }) {
  return (
    <Typography variant="body2" color={color} align="center">
      <Link color="inherit" href="https://epic.cc.ic.ac.uk/">
        {"© "}
        2020-{new Date().getFullYear()} Imperial College London
      </Link>{" "}
    </Typography>
  );
}

const Footer = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))(({ classes, color = "textSecondary" }) => {
  return (
    <footer className={classes.root}>
      <Copyright color={color} />
    </footer>
  );
});

export default Footer;
