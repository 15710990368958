import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";

import { Checkbox, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  IndeterminateCheckBox as IndeterminateCheckBoxIcon,
} from "@material-ui/icons";

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIconPositive = <CheckBoxIcon />;
const checkedIconNegative = <IndeterminateCheckBoxIcon />;

export default function CheckboxSelect({
  options,
  enabled,
  onChange,
  title,
  description,
  defaultChecked = true,
  negative = false,
}) {
  const theme = useTheme();

  const onChangeHandler = (event, options, reason) => {
    var newOptions = options;
    if (reason == "create-option") {
      const i = options.length - 1;
      if (typeof options[i] == "string") {
        newOptions[i] = {
          key: options[i],
          value: true,
        };
      }
    }
    onChange(newOptions.map((option) => option.key));
  };

  const getOptionLabel = (option) =>
    option.label != undefined ? option.label : option.key;

  return (
    <Autocomplete
      multiple
      freeSolo
      onChange={onChangeHandler}
      value={options.filter((option) => option.value)}
      options={options}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={negative ? checkedIconNegative : checkedIconPositive}
            checked={enabled ? selected : defaultChecked}
            style={{
              color:
                enabled && selected
                  ? negative
                    ? theme.palette.error.main
                    : theme.palette.success.main
                  : theme.palette.secondary.faded,
              marginRight: 8,
            }}
            // onChange={onChangeHandler}
            name={option.key}
            key={"checkbox_" + option.id}
          />
          {getOptionLabel(option)}
        </>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={title}
          placeholder={description}
        />
      )}
    />
  );
}
