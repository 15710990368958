import React, { useState } from "react";

import { Box, Dialog, Button, IconButton } from "@material-ui/core";

import { InfoRounded as InfoIcon } from "@material-ui/icons";

import { DialogTitle, DialogContent, DialogActions } from "./CustomDialog.js";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";

export default function MoreInfoDialog({
  open,
  title = "Info",
  children,
  handleClose,
}) {
  return (
    <Dialog
      fullWidth
      open={open}
      scroll="paper"
      fullWidth={true}
      onClose={handleClose}
    >
      <DialogTitle onClose={handleClose}>
        <Box alignItems="center" display="flex" flexDirection="row">
          <InfoIcon />
          <Box px={1}>{title}</Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box pb={2} pt={1}>
          {children}
        </Box>
      </DialogContent>
      {/* <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}

export function MoreInfoButton({ ...props }) {
  return (
    <IconButton {...props}>
      <HelpOutlineRoundedIcon />
    </IconButton>
  );
}
