import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

import { Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { useParams } from "../api.js";
import MoreInfoDialog from "./MoreInfoDialog.js";

import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@material-ui/icons";

const ActiveButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.accent.main),
    backgroundColor: theme.palette.accent.main,
    "&:hover": {
      backgroundColor: theme.palette.accent.faded,
    },
  },
}))(Button);

const DeactiveButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.grey.main),
    backgroundColor: theme.palette.grey.main,
    "&:hover": {
      backgroundColor: theme.palette.grey.faded,
    },
  },
}))(Button);

function ColorButton({ active, ...props }) {
  return (
    <>{active ? <ActiveButton {...props} /> : <DeactiveButton {...props} />}</>
  );
}

function DiscoveryButton({
  value,
  label,
  message,
  onChange = () => undefined,
  handleLearnMore = () => undefined,
  ...props
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (
    <>
      <ColorButton
        active={value}
        startIcon={
          value ? (
            <>
              &nbsp;&nbsp;
              <CheckBoxIcon />
            </>
          ) : (
            <>
              &nbsp;&nbsp;
              <CheckBoxOutlineBlankIcon />
            </>
          )
        }
        onClick={(event) => {
          if (!value && message !== undefined) {
            enqueueSnackbar(message, {
              variant: "info",
              autoHideDuration: 15000,
              action: (key) => (
                <>
                  <Button
                    color="inherit"
                    onClick={() => {
                      handleLearnMore();
                      closeSnackbar(key);
                    }}
                  >
                    Learn More
                  </Button>
                  <Button color="inherit" onClick={() => closeSnackbar(key)}>
                    Dismiss
                  </Button>
                </>
              ),
            });
          }
          onChange(!value);
        }}
        margin={0}
        {...props}
      >
        {label}&nbsp;
      </ColorButton>
    </>
  );
}

export default function DiscoveryBox() {
  const [search, params, setParams, setParam] = useParams();

  const discovery =
    params.discovery !== undefined &&
    (params.discovery === true || params.discovery === "true");

  const [moreInfoDiscovery, setMoreInfoDiscovery] = useState(false);

  return (
    <>
      <MoreInfoDialog
        open={moreInfoDiscovery}
        title="Discovery"
        handleClose={() => setMoreInfoDiscovery(false)}
      >
        <Typography gutterBottom>
          With the <i>discovery</i> search mode the engine will match people
          using an ontology graph. The functionality may provide more accurate
          and varied results but is much slower to run the search (it could take
          few minutes).
        </Typography>
      </MoreInfoDialog>
      <DiscoveryButton
        label="Discovery"
        message="Search with discovery may take more time"
        value={discovery}
        onChange={(value) => setParam("discovery", value)}
        handleLearnMore={() => setMoreInfoDiscovery(true)}
        color="secondary"
      />
    </>
  );
}
